import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { fee_requests_list_for_student } from "../../DAL/FeesManagement/FeesManagement";
import { s3baseUrl } from "../../config/config";
import { currency_sign, show_proper_words } from "../../utils/constant";
import { useContentSetting } from "../../Hooks/ContentSetting";

export default function FeesManagement() {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [feesList, setFeesList] = useState([]);
  const { userInfo } = useContentSetting();

  const get_fees_list = async () => {
    const result = await fee_requests_list_for_student();
    if (result.code === 200) {
      const data = result.fee_requests.map((request) => {
        let guardian_name = "";
        if (result.client?.name) {
          let guardian_email = result.client.email;
          guardian_name = result.client.name + " (" + guardian_email + ")";
        }

        let table_avatar = {
          src: s3baseUrl + request.receipt_slip,
          alt: "Receipt Slip",
        };
        return { ...request, table_avatar, guardian_name };
      });
      setFeesList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      label: "Total Fee",
      renderData: (row) => (
        <div>{currency_sign(row.currency) + row.amount}</div>
      ),
    },
    {
      label: "My Fee",
      renderData: (row) => {
        let find_fee = row.students.find(
          (student) => student._id === userInfo._id
        );
        return <div>{currency_sign(row.currency) + find_fee.fee_amount}</div>;
      },
    },
    {
      id: "month",
      label: "Month",
      className: "text-capitalize",
    },
    {
      id: "year",
      label: "Year",
    },
    {
      id: "fee_status",
      label: "Fee Status",
      renderData: (row) => {
        let fee_status = row.fee_status;
        let class_name = "";
        if (fee_status === "pending") {
          class_name = "bg-danger";
        } else if (fee_status === "submitted") {
          class_name = "bg-primary";
        }
        return (
          <span className={`fee-status ${class_name}`}>
            {show_proper_words(fee_status)}
          </span>
        );
      },
    },
    {
      id: "table_avatar",
      label: "Receipt Slip",
      type: "thumbnail",
      show_online: true,
      className: "reduce-cell-padding",
    },
  ];

  useEffect(() => {
    get_fees_list();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="mb-2 mui-table-heading">
                <h2>Fees List</h2>
              </div>
              <CustomMUITable TABLE_HEAD={TABLE_HEAD} data={feesList} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
