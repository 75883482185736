import { AppBar, Box, IconButton, Toolbar, Tooltip } from "@mui/material";
import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import AccountPopover from "../../components/Header/AccountPopover";
import ConfirmationPopup from "../../components/GeneralComponents/ConfirmationPopup";
import { switch_student_account_api } from "../../DAL/LogedInUser/LogedInUser";
import { useSnackbar } from "notistack";
import { useContentSetting } from "../../Hooks/ContentSetting";
import { useLocation, useNavigate } from "react-router-dom";
import { logo } from "../../assets";
import { s3baseUrl } from "../../config/config";
import { useEffect } from "react";
import DraggableBox from "./CallingBox/DraggableBox";
import { useClassRoom } from "../../Hooks/ClassRoom";

export default function AppHeader({
  drawerWidth,
  handleDrawerToggle,
  setIsLoading,
  handlePermanentDrawer,
}) {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [userName, setUserName] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { lastCallID, isDisconnecting, handleEndCall } = useClassRoom();
  const {
    setUserInfo,
    studentsGroup,
    userInfo,
    setConversation,
    setUnreadMessageCount,
    unreadMessageCount,
    socket,
    STOP_SOCKET_FORCEFULLY,
    START_SOCKET_FORCEFULLY,
  } = useContentSetting();

  const location = useLocation();
  const is_class_route = location.pathname.includes("class-room");

  const handleClick = (student) => {
    if (student._id === userInfo._id) return;
    setUserName(student);
    setOpenConfirm(true);
  };

  const handleClassRoom = () => {
    setUnreadMessageCount(0);
    navigate("/class-room");
  };

  const handleSwitchAccount = async () => {
    handleEndCall({ stop_call: true });
    setOpenConfirm(false);
    setIsLoading(true);
    STOP_SOCKET_FORCEFULLY();
    let postData = { student_id: userName._id };
    const result = await switch_student_account_api(postData);
    if (result.code === 200) {
      localStorage.setItem(`user_data`, JSON.stringify(result.student));
      localStorage.setItem("token", result.token);
      START_SOCKET_FORCEFULLY(result.student._id);
      setConversation(result.conversation);
      setUserInfo(result.student);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const showNotification = (data) => {
    let find_student = data.members.find(
      (user) => user.user_type === "student"
    );
    let find_member = data.members.find(
      (member) => member._id === userInfo._id
    );
    let notification_title = `${find_member.name} messages to ${find_student.name}`;

    let profile_image = logo;
    if (find_student?.profile_image) {
      profile_image = s3baseUrl + find_student?.profile_image;
    }
    new Notification(notification_title, {
      body: data.message.message,
      icon: profile_image,
    });
  };

  const getNotificationPermission = (dataObject) => {
    if (Notification.permission === "granted") {
      showNotification(dataObject);
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          showNotification(dataObject);
        }
      });
    }
  };

  useEffect(() => {
    if (!is_class_route) {
      socket.on("send_message_from_class_receiver", (data) => {
        let find_user = data.members.find((user) => user._id === userInfo._id);
        if (find_user) {
          getNotificationPermission(data);
          setUnreadMessageCount((old) => old + 1);
        }
      });
    }

    return () => {
      if (!is_class_route) {
        socket.off("send_message_from_class_receiver");
      }
    };
  }, [is_class_route]);

  return (
    <>
      {isDisconnecting && (
        <div className="is-disconnecting-call">
          <div className="disconnecting-text">
            <h2>Connection with the School has been lost.</h2>
            <h2> Waiting to reconnect...</h2>
          </div>
        </div>
      )}
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        className="app-header"
      >
        {lastCallID && <DraggableBox />}
        {/* <DraggableBox /> */}
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { xs: "block", sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handlePermanentDrawer}
            sx={{ mr: 2, display: { xs: "none", sm: "block" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
        {studentsGroup?.length > 1 && (
          <div className="mt-2 mt-md-4 d-flex flex-wrap justify-content-center">
            {studentsGroup.map((student) => {
              return (
                <Tooltip
                  title={student._id !== userInfo._id ? "Switch Account?" : ""}
                >
                  <div
                    className={`student-group mb-2 ${
                      student._id === userInfo._id ? "active-student" : ""
                    }`}
                    onClick={() => {
                      handleClick(student);
                    }}
                  >
                    {student.name}{" "}
                  </div>
                </Tooltip>
              );
            })}
          </div>
        )}
        <div className="d-flex">
          {unreadMessageCount > 0 && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <div className="header-button-action" onClick={handleClassRoom}>
                <span>{unreadMessageCount}</span>
                <i className="fa-solid fa-comment-dots"></i>
              </div>
            </Box>
          )}
          <AccountPopover />
        </div>
      </AppBar>

      <ConfirmationPopup
        openDelete={openConfirm}
        setOpenDelete={setOpenConfirm}
        title={`Are you sure you want to switch to ${userName.name}'s Account?`}
        handleAgree={handleSwitchAccount}
      />
    </>
  );
}
