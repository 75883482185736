import { invokeApi } from "../../bl_libs/invokeApi";

export const fee_requests_list_for_student = async () => {
  const requestObj = {
    path: `api/fees_management/fee_requests_list_for_student`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
