const get_icon = (name) => {
  return <i className={`nav-icon ${name}`}></i>;
};

const sidebarConfig = () => {
  let sidebarMenus = [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: get_icon("fas fa-tachometer-alt"),
    },
    {
      title: "Students Reports",
      path: "/students-reports",
      icon: get_icon("fa-solid fa-list"),
    },
    {
      title: "Class Room",
      path: "/class-room",
      icon: get_icon("fas fa-users"),
    },
    {
      title: "Lesson Books",
      path: "/lesson-books",
      icon: get_icon("fas fa-book-reader"),
    },
    {
      title: "Leaves",
      path: "/leave-requests",
      icon: get_icon("fa-solid fa-person-walking-arrow-right"),
    },
    {
      title: "Fees Management",
      path: "/fees-management",
      icon: get_icon("fa-solid fa-comment-dollar"),
    },
    {
      title: "Personal Notes",
      path: "/personal-notes",
      icon: get_icon("fa-solid fa-book"),
    },
    {
      title: "To Do Lists",
      path: "/to-do-lists",
      icon: get_icon("fa-solid fa-list"),
    },
    {
      title: "Announcements",
      path: "/announcements",
      icon: get_icon("fas fa-bullhorn"),
    },
  ];

  return sidebarMenus;
};
export default sidebarConfig;
